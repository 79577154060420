import React, { useState, useEffect } from "react";
import axios from "axios";

const IPAddress = () => {
  const [ipAddress, setIPAddress] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipResponse.data.ip;
        setIPAddress(ipAddress);

        const countryResponse = await axios.get(
          `http://ip-api.com/json/${ipAddress}`
        );
        const country = countryResponse.data.country;
        setCountry(country);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchIPAddress();

    // Cleanup function to prevent memory leaks
    return () => {
      // cleanup code
    };
  }, []);

  return (
    <div>
      {/* <p>Your IP Address: {ipAddress}</p> */}
      <p> {country}</p>
    </div>
  );
};

export default IPAddress;
